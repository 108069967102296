const fuel_consumption = {
    name: 'fuel_consumption',
    title: 'Средний расход топлива',
    url: '/settings/fuel_consumption',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.fuel_consumption.list',
        read: 'v1.fuel_consumption.read',
        create: 'v1.fuel_consumption.create',
        update: 'v1.fuel_consumption.update',
        delete: 'v1.fuel_consumption.delete',
    },
    listColumns: [{
            name: 'value',
            required: true,
            label: 'Расход',
            align: 'left',
            field: 'value',
            sortable: true,
        },
        {
            name: 'start_date',
            required: true,
            label: 'Дата начала',
            align: 'left',
            field: 'start_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'end_date',
            required: true,
            label: 'Дата окончания',
            align: 'left',
            field: 'end_date',
            sortable: true,
            type: 'date',
        },
    ],
    editColumns: [{
            name: 'value',
            required: true,
            label: 'Сумма',
            align: 'left',
            field: 'value',
            sortable: true,
            type: 'number',
        },
        {
            name: 'start_date',
            required: true,
            label: 'Дата начала',
            align: 'left',
            field: 'start_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'end_date',
            required: true,
            label: 'Дата окончания',
            align: 'left',
            field: 'end_date',
            sortable: true,
            type: 'date',
        },
    ],
};

export {
    fuel_consumption,
};